import { countryCodes } from "CONSTANTS";
import { filterFactory } from "components/utils/withFilters";

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.DateRange,
    label: "data wystawienia",
    name: ["paymentDateFrom", "paymentDateTo"],
    showMonths: true,
  },
  {
    type: FilterType.Search,
    label: "kontrahent",
    name: "customers",
    multiple: true,
    searchBy: "customers",
  },
  {
    type: FilterType.Text,
    label: "NIP",
    placeholder: "Szukaj NIP",
    name: "taxId",
  },
  {
    type: FilterType.Select,
    label: "państwo",
    name: "countryCode",
    multiple: true,
    kind: "label",
    options: countryCodes.map(({ icon, value }) => ({
      label: value,
      value,
      icon,
    })),
  },
]);
